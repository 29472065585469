import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function Layout({
  children
}) {
  return <>
      {children}
    </>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Sed non risus. Suspendisse lectus tortor, dignissim sit
amet, adipiscing nec, ultricies sed, dolor.`}</p>
    {
      /* This default export overrides the default layout ensuring */
    }
    {
      /*  that the Message isn't wrapped by other elements */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      